import { FC, HTMLProps } from 'react'
import { isDocumentLink, isExternalOrFileLink, PrismicLink } from 'types/util'
import Link, { LinkProps } from 'next/link'
import { ANCHOR_ROUTES, isPageType, linkResolver } from 'utils'
import type { PropsWithChildren } from 'react'

interface FlexibleLinkProps {
  data: PrismicLink | string
  className?: string
  nextLinkProps?: Omit<LinkProps, 'href' | 'as'>
  aProps?: Omit<HTMLProps<HTMLAnchorElement>, 'className' | 'href'>
}

export const FlexibleLink: FC<PropsWithChildren<FlexibleLinkProps>> = ({
  data,
  className,
  nextLinkProps,
  aProps,
  children,
}) => {
  if (!data) {
    console.error('No data in FlexibleLink')
    return null
  }

  if (typeof data === 'string') {
    const pathProps = isPageType(data) ? { href: '/[[...page]]', as: data } : { href: data }

    return (
      <Link {...pathProps} {...nextLinkProps}>
        <a className={className} {...aProps}>
          {children}
        </a>
      </Link>
    )
  }

  if (isDocumentLink(data)) {
    const path = linkResolver(data)

    if (data._meta?.type && ANCHOR_ROUTES.includes(data._meta.type)) {
      return (
        <a href={path} className={className} {...aProps}>
          {children}
        </a>
      )
    }

    const pathProps = isPageType(path) ? { href: '/[[...page]]', as: path } : { href: path }

    return (
      <Link {...pathProps} {...nextLinkProps}>
        <a className={className} {...aProps}>
          {children}
        </a>
      </Link>
    )
  }

  if (isExternalOrFileLink(data)) {
    const { target, rel, ...otherAProps } = aProps || {}
    return (
      <a
        href={data.url}
        target={target || '_blank'}
        rel={rel || 'noreferrer'}
        className={className}
        {...otherAProps}
      >
        {children}
      </a>
    )
  }

  return null
}
