import Head from 'next/head'
import Script from 'next/script'
import { FC } from 'react'
import type { PropsWithChildren } from 'react'

interface MetaProps {
  title: string
  description: string
  meta_image?: string
  microsite?: string
}

export const Meta: FC<PropsWithChildren<MetaProps>> = ({
  title,
  description,
  meta_image,
  microsite,
}) => (
  <>
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content={meta_image || `${process.env.NEXT_PUBLIC_BASE_URL}/manifest/logo.png`}
      />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="msapplication-config" content="/manifest/browserconfig.xml" />
      <meta name="theme-color" content="#ffffff" />

      <link rel="apple-touch-icon" sizes="180x180" href="/manifest/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/manifest/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/manifest/favicon-16x16.png" />
      <link rel="manifest" href="/manifest/site.webmanifest" />
      <link rel="mask-icon" href="/manifest/safari-pinned-tab.svg" color="#5bbad5" />
      <link rel="shortcut icon" href="/manifest/favicon.ico" />
      <link
        rel="preload"
        href="/fonts/GintoNord-Black/GintoNord-Black.woff"
        as="font"
        crossOrigin=""
      />
      <link
        rel="preload"
        href="/fonts/GintoNormal-Regular/GintoNormal-Regular.woff"
        as="font"
        crossOrigin=""
      />
      <link
        rel="preload"
        href="/fonts/GintoNormal-RegularIta/GintoNormal-RegularIta.woff"
        as="font"
        crossOrigin=""
      />
      <link
        rel="preload"
        href="/fonts/GintoNord-Black/GintoNord-Black.otf"
        as="font"
        crossOrigin=""
      />
      <link
        rel="preload"
        href="/fonts/GintoNormal-Regular/GintoNormal-Regular.otf"
        as="font"
        crossOrigin=""
      />
      <link
        rel="preload"
        href="/fonts/GintoNormal-RegularIta/GintoNormal-RegularIta.otf"
        as="font"
        crossOrigin=""
      />

      {process.env.NEXT_PUBLIC_FB_ID && (
        <>
          <meta name="facebook-domain-verification" content={process.env.NEXT_PUBLIC_FB_ID} />
        </>
      )}
    </Head>
    <Script
      id="hs-script-loader"
      src="//js.hs-scripts.com/8614056.js"
      async
      defer
      strategy="lazyOnload"
    />
    {process.env.NEXT_PUBLIC_GTM_ID && (
      <>
        {/* Google Tag Manager */}
        <Script
          id="gtm"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}')`,
          }}
          strategy="lazyOnload"
        />
        {/* End Google Tag Manager */}
      </>
    )}
    {!microsite && (
      <>
        {/* Linkedin Pixel Code */}
        <Script
          id="pixel"
          dangerouslySetInnerHTML={{
            __html: `
            _linkedin_partner_id = "2978793";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);`,
          }}
          strategy="lazyOnload"
        />
        <Script
          id="pixel-2"
          dangerouslySetInnerHTML={{
            __html: ` 
            (function(){var s = document.getElementsByTagName("script")[0];
            var b = document.createElement("script");
            b.type = "text/javascript";b.async = true;
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
            s.parentNode.insertBefore(b, s);})();`,
          }}
          strategy="lazyOnload"
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html: `
            <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=2978793&fmt=gif" />`,
          }}
        />

        {/* End Linkedin Pixel Code */}

        {/* <!-- Twitter universal website tag code --> */}
        <Script
          id="twitter-1"
          dangerouslySetInnerHTML={{
            __html: `
            !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
            },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
            a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
            // Insert Twitter Pixel ID and Standard Event data below
            twq('init','o5o01');
            twq('track','PageView');`,
          }}
          strategy="lazyOnload"
        />
        <Script
          id="twitter-2"
          dangerouslySetInnerHTML={{
            __html: `
            !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
            },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
            a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
            // Insert Twitter Pixel ID and Standard Event data below
            twq('init','o5o04');
            twq('track','PageView');`,
          }}
          strategy="lazyOnload"
        />
        {/* <!-- End Twitter universal website tag code --> */}
      </>
    )}
  </>
)
