import { Elements, HTMLSerializer, RichTextSpan } from 'prismic-reactjs'
import { ReactNode } from 'react'
import { DocumentLink, PrismicEmbed } from 'types/util'
import { _ExternalLink, _FileLink } from 'types/graphql'
import { FlexibleLink } from 'components/Shared/FlexibleLink'
import { getHeightInPercentageOfWidth } from 'utils/util'

const serializePrismicLink = (span: RichTextSpan, children: ReactNode[], key: string) => {
  const link = span.data
  let transformedData

  if (link?.link_type === 'Document') {
    transformedData = {
      _meta: {
        type: link?.type!,
        uid: link?.uid || link?.slug,
      },
    } as DocumentLink
  } else {
    transformedData = {
      url: link?.url!,
      target: link?.target,
    } as _ExternalLink | _FileLink
  }

  return (
    <FlexibleLink data={transformedData} className="underline hover:no-underline" key={key}>
      {children}
    </FlexibleLink>
  )
}

const serializePrismicEmbed = (oembed: PrismicEmbed, key: string) => (
  <div
    key={key}
    dangerouslySetInnerHTML={{ __html: oembed.html }}
    className="video-embed"
    style={{
      paddingBottom: getHeightInPercentageOfWidth(oembed.width, oembed.height),
    }}
  />
)

export const prismicHtmlSerializer: HTMLSerializer<ReactNode> = (
  type,
  element,
  content,
  children,
  key
) => {
  switch (type) {
    case Elements.hyperlink:
      return serializePrismicLink(element, children, key)
    case Elements.embed:
      return serializePrismicEmbed(element.oembed, key)
    default:
      return null
  }
}
