import Arrow from 'assets/icons/arrow.svg'
import ChevronRight from 'assets/icons/chevron_full_right.svg'
import cn from 'classnames'
import { PrismicLink } from 'types/util'
import { FlexibleLink } from 'components/Shared/FlexibleLink'
import { FC, MouseEvent as ReactMouseEvent } from 'react'
import type { PropsWithChildren } from 'react'

interface Props {
  href?: PrismicLink | string
  targetBlank?: boolean
  onClick?: (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void
  submit?: boolean
  type?: 'button' | 'link'
  customClass?: string
  disabled?: boolean
  noWrap?: boolean | null
  whitespaceWrap?: boolean
  center?: boolean
  inlineArrow?: boolean
  alignLeftMobile?: boolean
  fontSizeClasses?: string
}

export const Button: FC<PropsWithChildren<Props>> = ({
  href,
  onClick,
  submit,
  type,
  children,
  customClass,
  disabled,
  noWrap,
  whitespaceWrap,
  center,
  inlineArrow,
  alignLeftMobile,
  fontSizeClasses,
}) => {
  const isLink = type === 'link'

  const className = cn(
    inlineArrow ? 'block' : 'flex',
    'font-heading tracking-wider disabled:cursor-default',
    { 'items-center': center },
    { 'sm:items-center': alignLeftMobile },
    isLink
      ? cn(
          'bg-transparent text-black group',
          fontSizeClasses || 'text-12 xs:text-14',
          !noWrap && 'flex-col lg:inline-flex lg:flex-row',
          !whitespaceWrap && 'whitespace-nowrap',
          !disabled ? 'text-black' : 'text-alto'
        )
      : cn(
          'border border-white text-white transition-all duration-500',
          fontSizeClasses || 'text-9 sm:text-11',
          !disabled && 'hover:bg-white hover:text-black'
        )
  )

  const content = isLink ? (
    <>
      <div className={cn(!disabled && 'lg:group-hover group-hover:animate-textScaleAndHide')}>
        {children}
      </div>
      <div
        className={cn(
          'mb-3',
          !disabled && 'lg:group-hover transform group-hover:animate-arrowBounceRight',
          noWrap ? 'ml-20' : 'mt-10 lg:mt-0 lg:ml-20',
          inlineArrow && 'inline'
        )}
      >
        <Arrow />
      </div>
    </>
  ) : (
    children
  )

  if (href) {
    return (
      <FlexibleLink data={href} className={cn(className, customClass)}>
        {content}
      </FlexibleLink>
    )
  }

  return (
    <button
      onClick={onClick}
      className={cn(className, customClass)}
      type={submit ? 'submit' : 'button'}
      disabled={disabled}
    >
      {submit ? (
        <>
          <span className="border-r px-22 py-18">{content}</span>
          <div className="px-22 py-18">
            <ChevronRight />
          </div>
        </>
      ) : (
        content
      )}
    </button>
  )
}
