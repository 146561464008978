import { FC, useEffect } from 'react'
import Logo from 'assets/icons/logo.svg'
import Close from 'assets/icons/menu.svg'
import { ROUTES } from 'utils'
import cn from 'classnames'
import { Layout } from 'types/graphql'
import { MobileNavSubmenu } from 'components/Layout/Header/MobileNav/MobileNavSubmenu'
import { MobileNavLink } from 'components/Layout/Header/MobileNav/MobileNavLink'
import { FlexibleLink } from 'components/Shared/FlexibleLink'
import type { PropsWithChildren } from 'react'

export type ToggleMenu = {
  toggleMenu: () => void
}

interface Props {
  active: boolean
  lang?: string
  toggleMenu: () => void
  links: Layout['nav']
}

export const MobileNav: FC<PropsWithChildren<Props>> = ({ active, toggleMenu, links }) => {
  useEffect(() => {
    const layoutInner = document.getElementById('layoutInner')
    active
      ? layoutInner?.classList.add('overflow-y-hidden')
      : layoutInner?.classList.remove('overflow-y-hidden')
  }, [active])

  return (
    <>
      <button
        className={cn(
          'hamburger absolute right-20 z-30 ml-auto block focus:outline-none lg:hidden',
          active ? 'menu-open' : ''
        )}
        onClick={toggleMenu}
      >
        <Close />
      </button>
      <div
        className={cn(
          'hide-scrollbar fixed top-0 bottom-0 left-0 right-0 z-10 flex h-screen w-screen flex-col bg-white lg:hidden',
          active ? 'block' : 'hidden'
        )}
      >
        <div className="flex px-20 py-20">
          <FlexibleLink data={ROUTES.index} nextLinkProps={{ prefetch: false }}>
            <Logo className="text-black-100 w-20 fill-current" />
          </FlexibleLink>
        </div>
        <nav className="overflow-y-scroll px-20">
          <div className="pb-20 text-68">
            {links?.map((link, i) => {
              switch (link.__typename) {
                case 'LayoutNavNav_link':
                  return <MobileNavLink primary={link.primary} toggleMenu={toggleMenu} key={i} />
                case 'LayoutNavNav_submenu':
                  return (
                    <MobileNavSubmenu
                      primary={link.primary}
                      fields={link.fields}
                      toggleMenu={toggleMenu}
                      key={i}
                    />
                  )
              }
            })}
          </div>
        </nav>
      </div>
    </>
  )
}
