import { FC, useState } from 'react'
import { LayoutNavNav_Submenu } from 'types/graphql'
import { ToggleMenu } from 'components/Layout/Header/MobileNav/MobileNav'
import { FlexibleLink } from 'components/Shared/FlexibleLink'
import type { PropsWithChildren } from 'react'

export const MobileNavSubmenu: FC<
  PropsWithChildren<Pick<LayoutNavNav_Submenu, 'primary' | 'fields'> & ToggleMenu>
> = ({ primary, fields, toggleMenu }) => {
  const [subActive, setSubActive] = useState<boolean>(false)

  return (
    <div>
      <button
        className="flex w-full items-center focus:outline-none"
        onClick={() => setSubActive(!subActive)}
      >
        <div className="flex py-10 text-left lowercase">
          {primary?.label}
          <sup className="static mb-auto ml-4 mt-7 text-24">{fields?.length}</sup>
        </div>
      </button>
      <div className={`${subActive ? 'block' : 'hidden'} py-8`}>
        {fields?.map((field, i) => (
          <FlexibleLink
            key={i}
            data={field.link}
            className="block py-8 pl-2 text-20"
            aProps={{ onClick: toggleMenu }}
            nextLinkProps={{ prefetch: false }}
          >
            {field.label}
          </FlexibleLink>
        ))}
      </div>
    </div>
  )
}
