const recaptchaErrorCodes: { [code: string]: string } = {
  'missing-input-secret': 'The secret parameter is missing',
  'invalid-input-secret': 'The secret parameter is invalid or malformed',
  'missing-input-response': 'The response parameter is missing',
  'invalid-input-response': 'The response parameter is invalid or malformed',
  'bad-request': 'The request is invalid or malformed',
}

const mapRecaptchaErrors = (errors: string[] | undefined): string[] | undefined => {
  return errors?.map(err => {
    return recaptchaErrorCodes[err]
  })
}

type RecaptchaVerifyResponse = {
  success: boolean // whether this request was a valid reCAPTCHA token for your site
  score: number // the score for this request (0.0 - 1.0)
  action: string // the action name for this request (important to verify)
  challenge_ts: string // timestamp of the challenge load (ISO format yyyy-MM-dd'T'HH:mm:ssZZ)
  hostname: string // the hostname of the site where the reCAPTCHA was solved
  'error-codes': string[] // optional
}

export const verifyRecaptcha = async (token: string) => {
  try {
    const response = await fetch(
      `https://www.google.com/recaptcha/api/siteverify?secret=${process.env.RECAPTCHA_SECRET}&response=${token}`
    )

    const result: RecaptchaVerifyResponse = await response.json()
    if (!result.success) {
      console.error('reCAPTCHA verification error:', mapRecaptchaErrors(result['error-codes']))
      throw 'reCAPTCHA verification returned some errors'
    }

    if (result.score < 0.5) {
      return false
    }
  } catch (ex) {
    console.error(ex)
    throw 'Something went wrong during reCAPTCHA verification'
  }

  return true
}
