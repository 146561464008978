import { FC } from 'react'
import { LayoutNavNav_Link } from 'types/graphql'
import { FlexibleLink } from 'components/Shared/FlexibleLink'
import cn from 'classnames'
import type { PropsWithChildren } from 'react'

export const DesktopNavLink: FC<PropsWithChildren<Pick<LayoutNavNav_Link, 'primary'>>> = ({
  primary,
}) => {
  const highlightClass = 'bg-orange rounded-full duration-500 hover:bg-organge100 px-16'

  return (
    <>
      {!primary?.telus_nav_link_mobile && (
        <FlexibleLink
          data={primary?.link}
          className={cn(
            'nav-item flex items-center px-20 duration-500 first:pl-0 last:pr-0',
            !primary?.telus_nav_link_desktop && 'group-hover:opacity-60'
          )}
          nextLinkProps={{ prefetch: false }}
        >
          <span className={cn(primary?.telus_nav_link_desktop && highlightClass)}>
            {primary?.label}
          </span>
        </FlexibleLink>
      )}
    </>
  )
}
