import { forwardRef, useState } from 'react'
import cn from 'classnames'
import { OptionalLabel } from './OptionalLabel'

interface InputFormField {
  name: string
  type?: string
  label?: string | null
  isContactPage?: boolean
  isBigger?: boolean
  errors?: string
  isEmpty: boolean
  isOptional?: boolean
}

export const InputFormField = forwardRef<HTMLInputElement, InputFormField>(
  ({ name, type, label, isContactPage, isBigger, errors, isEmpty, isOptional }, ref) => {
    const [inFocus, setInFocus] = useState(false)

    const moveLabel = isEmpty || inFocus

    const labelStyle = moveLabel
      ? {
          transform: 'translateY(-1.5em) scale(0.75)',
          opacity: 0.5,
        }
      : {}

    const inputStyle = isContactPage
      ? 'border-alto text-black100'
      : 'border-black200 autofill-transparent'

    return (
      <div className="relative w-full sm:text-24">
        <label
          htmlFor={name}
          className={cn(
            'pointer-events-none absolute top-12 w-full transition-all duration-200',
            isContactPage ? 'text-gray' : 'text-cloud',
            isBigger && !moveLabel && 'xl:text-76'
          )}
          style={labelStyle}
        >
          <span className={cn(isContactPage ? 'flex w-fit-content' : '')}>{label}</span>
        </label>
        {isOptional && <OptionalLabel />}
        <input
          ref={ref}
          type={type}
          name={name}
          onFocus={() => {
            setInFocus(true)
          }}
          onBlur={() => {
            setInFocus(false)
          }}
          className={cn('mt-0 block w-full', inputStyle, type === 'file' ? 'opacity-0' : '')}
          style={{ lineHeight: isContactPage ? '160%' : 'inherit' }}
        />
        {errors && <p className="text-16 text-red">{errors}</p>}
      </div>
    )
  }
)
