import {
  _Document,
  _ExternalLink,
  _FileLink,
  _Linkable,
  Microsite_Subpage,
  PageConnectionEdge,
  ServiceConnectionEdge,
} from 'types/graphql'

export type PrismicImage = {
  alt?: string | null
  copyright?: string | null
  dimensions?: { width: number; height: number }
  url?: string
}

export type PrismicEmbed = {
  width: number
  height: number
  embed_url: string
  type: string
  version: string
  title: string
  author_name: string
  author_url: string
  provider_name: string
  provider_url: string
  cache_age: null
  thumbnail_url: string
  thumbnail_width: number
  thumbnail_height: number
  html: string
}

export type DocumentLink = _Document & { microsite?: _Document }

export type PrismicLink = DocumentLink | _ExternalLink | _FileLink | _Linkable | null | undefined

export function isDocumentLink(data: NonNullable<PrismicLink>): data is DocumentLink {
  return '_meta' in data
}

export function isExternalOrFileLink(
  data: NonNullable<PrismicLink>
): data is _ExternalLink | _FileLink {
  return 'url' in data
}

export interface MainSiteLinks {
  pages: PageConnectionEdge[]
  services: ServiceConnectionEdge[]
}

type MicrositeLink = _Document & {
  title: Microsite_Subpage['title']
  microsite: _Document & { title: string }
}

export type MicrositeLinks = {
  link: MicrositeLink
}[]

export interface NodeUid {
  node: {
    _meta: { uid: string }
  }
}

export interface LinkableUrl extends _Linkable {
  url: string
}

export function isLinkableUrl(
  linkableUrl: _Linkable | undefined | null
): linkableUrl is LinkableUrl {
  return !!linkableUrl?.hasOwnProperty('url')
}
