import { FC, useState } from 'react'
import { LayoutNavNav_Submenu } from 'types/graphql'
import AnimateHeight from 'react-animate-height'
import classNames from 'classnames'
import { animated, useSpring } from 'react-spring'
import { FlexibleLink } from 'components/Shared/FlexibleLink'
import type { PropsWithChildren } from 'react'

interface SubmenuCounterProps {
  itemsNum?: number
  active: boolean
}

const SubmenuCounter: FC<PropsWithChildren<SubmenuCounterProps>> = ({ itemsNum, active }) => {
  const itemsCounter = useSpring({
    config: { duration: 500 },
    number: active ? 0 : itemsNum,
    from: { number: active ? itemsNum : 0 },
  })

  return (
    <animated.sup className="static ml-3 mb-auto mt-1 w-10 text-11">
      {itemsCounter.number?.to(number => Math.ceil(number))}
    </animated.sup>
  )
}

export const DesktopNavSubmenu: FC<
  PropsWithChildren<Pick<LayoutNavNav_Submenu, 'primary' | 'fields'>>
> = ({ primary, fields }) => {
  const [subActive, setSubActive] = useState<boolean>(false)

  return (
    <div
      className="nav-item cursor-pointer px-20 py-10 first:pl-0 last:pr-0"
      onMouseEnter={() => setSubActive(true)}
      onMouseLeave={() => setSubActive(false)}
    >
      <div className="flex items-center">
        <div className="nav-item flex h-full items-center duration-500 group-hover:opacity-60">
          {primary?.label}
        </div>
        <SubmenuCounter itemsNum={fields?.length} active={subActive} />
      </div>
      <AnimateHeight
        className={classNames(
          'absolute -translate-x-48 transform cursor-default overflow-hidden px-20'
        )}
        height={subActive ? 'auto' : 0}
        easing={'linear'}
        duration={500}
      >
        {fields?.map((field, i) => (
          <FlexibleLink
            key={i}
            data={field.link}
            className="mb-10 block w-fit-content rounded-full bg-white py-12 px-24 shadow-menu first:mt-20 last:mb-24"
            nextLinkProps={{ prefetch: false }}
          >
            {field.label}
          </FlexibleLink>
        ))}
      </AnimateHeight>
    </div>
  )
}
