import { forwardRef, useState } from 'react'
import cn from 'classnames'

interface Props {
  ref?: () => void
  isContactPage?: boolean
  name?: string
  dark?: boolean
}

export const Checkbox = forwardRef<HTMLInputElement, Props>(
  ({ isContactPage, name, dark }, ref) => {
    const [checked, setChecked] = useState(false)

    const handleChange = () => {
      setChecked(!checked)
    }

    const checkedStyle = {
      border: cn(
        'bg-transparent border absolute left-0 top-0 h-20 w-20 flex justify-center items-center',
        isContactPage ? 'border-alto' : checked && !dark ? 'border-white' : 'border-darkgray'
      ),
      inside: cn(
        'h-10 w-10',
        checked && (isContactPage ? 'bg-alto' : dark ? 'bg-darkgray' : 'bg-white')
      ),
    }

    return (
      <>
        <input
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          className="invisible mr-18"
          name={name}
          ref={ref}
        />
        <div className={checkedStyle.border}>
          <div className={checkedStyle.inside}></div>
        </div>
      </>
    )
  }
)
