import { Layout } from 'types/graphql'
import cn from 'classnames'
import { DesktopNavLink } from 'components/Layout/Header/DesktopNav/DesktopNavLink'
import { DesktopNavSubmenu } from 'components/Layout/Header/DesktopNav/DesktopNavSubmenu'
import { FC, useEffect, useRef } from 'react'
import useHover from 'utils/useHover'
import type { PropsWithChildren } from 'react'

interface Props {
  handleHover: (isHover: boolean) => void
  lang?: string
  className?: string
  links: Layout['nav']
}

export const DesktopNav: FC<PropsWithChildren<Props>> = ({ links, className, handleHover }) => {
  const hoverRef = useRef(null)
  const isHovered = useHover(hoverRef)

  useEffect(() => {
    handleHover(isHovered)
  }, [isHovered, handleHover])

  return (
    <nav className={cn('ml-auto hidden h-full items-center text-14 lg:flex', className)}>
      <div ref={hoverRef} className="group flex">
        {links?.map((link, i) => {
          switch (link.__typename) {
            case 'LayoutNavNav_link':
              return <DesktopNavLink primary={link.primary} key={i} />
            case 'LayoutNavNav_submenu':
              return <DesktopNavSubmenu primary={link.primary} fields={link.fields} key={i} />
          }
        })}
        {/* {lang && <LanguageSwitcher lang={lang} />} */}
      </div>
    </nav>
  )
}
