import { DocumentLink } from 'types/util'
import { Maybe } from 'types/graphql'

type Slug = Maybe<string> | undefined

export const isMicrositeDomain = () => {
  const nonMicrositeHostnames = ['localhost', 'applifting']
  return (
    typeof window !== 'undefined' &&
    !nonMicrositeHostnames.some(nonMicrositeHostname =>
      window.location.hostname.includes(nonMicrositeHostname)
    )
  )
}

const determineMicrositeLink = (slug: Slug, microsite?: Slug) => {
  const pathArray = [microsite, slug].filter(Boolean)
  if (isMicrositeDomain()) {
    pathArray.splice(0, 1)
  }
  return `/${pathArray.join('/')}`
}

// resolved is "page" custom type
export const isPageType = (link: string) =>
  [ROUTES.index, ROUTES['about-us'], ROUTES.careers].includes(link)

export const ROUTES = {
  article: (slug: Slug) => `/blog/${slug}`,
  case_study: (slug: Slug) => `/case-study/${slug}`,
  ebook: (slug: Slug) => `/ebook/${slug}`,
  ebooks: '/ebooks',
  webinar: (slug: Slug) => `/seminar/${slug}`,
  webinars: '/seminars',
  podcasts: '/podcasts',
  knowledge_hub: '/knowledge-hub',
  job_offer: (slug: Slug) => `/job-offer/${slug}`,
  live_case_study: (slug: Slug) => `/case-study/live/${slug}`,
  live_cs_article: (slug: Slug) => `/blog/live/${slug}`,
  index: '/',
  'about-us': '/about-us',
  careers: '/careers',
  savelives: '/savelives',
  quiz: (slug: Slug) => `/quiz/${slug}`,
  service: (slug: Slug) => `/service/${slug}`,
  blog: '/blog',
  contact: '/contact',
  our_work: '/our-work',
  footer_form_anchor: '#footer-form',
  microsite_landing_page: (slug: Slug) => determineMicrositeLink(null, slug),
  microsite_subpage: (slug: Slug, microsite?: Slug) => determineMicrositeLink(slug, microsite),
  microsite_article: (slug: Slug, microsite?: Slug) => determineMicrositeLink(slug, microsite),
  microsite_webinar: (slug: Slug, microsite?: Slug) => determineMicrositeLink(slug, microsite),
}

export const ANCHOR_ROUTES = ['footer_form_anchor']

// ts workaround
const routesCallableDynamically: {
  [key: string]: string | ((slug: string, microsite?: Slug) => string)
} = ROUTES

const getRouteProperty = (propertyName: string) => routesCallableDynamically[propertyName]

export const linkResolver = (link: DocumentLink) => {
  if (!link._meta) {
    console.error('No "_meta"')
    return '/#'
  }

  if (!link._meta.uid && !link._meta.type) {
    console.error('No "type" and "uid" in "_meta". All document links need at least one of those.')
    return '/#'
  }

  const routeProperty = getRouteProperty(
    (link._meta.type === 'page' ? link._meta.uid : link._meta.type || link._meta.uid) || ''
  )

  if (typeof routeProperty === 'function' && link._meta.uid)
    return routeProperty(link._meta.uid, link.microsite?._meta.uid)
  if (typeof routeProperty === 'string') return routeProperty
  return '/#'
}
