import { FC } from 'react'
import { LayoutNavNav_Link } from 'types/graphql'
import { ToggleMenu } from 'components/Layout/Header/MobileNav/MobileNav'
import { FlexibleLink } from 'components/Shared/FlexibleLink'
import type { PropsWithChildren } from 'react'

export const MobileNavLink: FC<
  PropsWithChildren<Pick<LayoutNavNav_Link, 'primary'> & ToggleMenu>
> = ({ primary, toggleMenu }) => (
  <>
    {!primary?.telus_nav_link_desktop && (
      <FlexibleLink
        data={primary?.link}
        className="block py-10"
        aProps={{ onClick: toggleMenu }}
        nextLinkProps={{ prefetch: false }}
      >
        {primary?.label}
      </FlexibleLink>
    )}
  </>
)
