import * as prismic from '@prismicio/client'
import compress from 'graphql-query-compress'
import { ServiceConnectionEdge } from 'types/graphql'
import { FetchOpts, FetchPreviewData } from 'types/fetchAPI'

export const REPOSITORY = process.env.NEXT_PUBLIC_PRISMIC_REPOSITORY_NAME
const GRAPHQL_API_URL = `https://${REPOSITORY}.cdn.prismic.io/graphql`
const REST_API_ENDPOINT = prismic.getEndpoint(REPOSITORY!)

export const prismicClient = prismic.createClient(REST_API_ENDPOINT)

export async function fetchAPI(query: string, { previewData, variables }: Partial<FetchOpts> = {}) {
  const res = await fetch(
    `${GRAPHQL_API_URL}?query=${compress(query)}&variables=${JSON.stringify(variables)}`,
    {
      headers: {
        'Prismic-Ref':
          (previewData as FetchPreviewData)?.ref || (await prismicClient.getMasterRef()).ref,
        'Content-Type': 'application/json',
      },
    }
  )

  if (res.status !== 200) {
    console.log(await res.text())
    throw new Error('Failed to fetch API')
  }

  const json = await res.json()

  if (json.errors) {
    console.error(JSON.stringify(json.errors, null, 2))
    throw new Error('Failed to parse fetched API data')
  }
  return json.data
}

export const filterProductionServices = (
  serviceEdges: ServiceConnectionEdge[] | null | undefined
) => {
  const allServicesEnvs = ['localhost', 'staging']
  if (
    allServicesEnvs.some(
      env => typeof window !== 'undefined' && window.location.hostname.includes(env)
    )
  )
    return serviceEdges || []

  return serviceEdges?.filter(({ node: { production } }) => !!production) || []
}
