import cn from 'classnames'
import { FC } from 'react'
import type { PropsWithChildren } from 'react'

interface Props {
  className?: string
  newLineAferColon?: boolean
  small?: boolean
}

const addNewLineAfterColon = (text: string) => text.replace(': ', ':\n')

export const Label: FC<PropsWithChildren<Props>> = ({
  children,
  className,
  small,
  newLineAferColon,
}) => (
  <p
    className={cn(
      'whitespace-pre-wrap text-12 uppercase tracking-extra-wide',
      { 'sm:text-14': !small },
      className
    )}
  >
    {newLineAferColon ? addNewLineAfterColon(children?.toString() || '') : children}
  </p>
)
