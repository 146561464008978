import cn from 'classnames'
import { FC } from 'react'
import type { PropsWithChildren } from 'react'

interface Props {
  className?: string
  noPaddingX?: boolean
  noMarginY?: boolean
}

export const SectionMain: FC<PropsWithChildren<Props>> = ({
  className,
  noPaddingX,
  noMarginY,
  children,
}) => (
  <section
    className={cn(
      'flex w-full',
      { 'px-15 lg:px-80': !noPaddingX, 'my-50 lg:my-100': !noMarginY },
      className
    )}
  >
    <div className="mx-auto flex w-full max-w-1280 flex-col">{children}</div>
  </section>
)
export const SectionCaseStudies: FC<PropsWithChildren<Props>> = ({
  className,
  noPaddingX,
  noMarginY,
  children,
}) => (
  <section
    className={cn(
      'flex w-full',
      { 'px-0 lg:px-0': !noPaddingX, 'my-50 lg:my-100': !noMarginY },
      className
    )}
  >
    <div className="mx-auto flex w-full flex-col">{children}</div>
  </section>
)
