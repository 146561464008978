import { FC, useCallback, useEffect, useState } from 'react'
import { DesktopNav } from 'components/Layout/Header/DesktopNav/DesktopNav'
import { MobileNav } from 'components/Layout/Header/MobileNav/MobileNav'
import { ROUTES } from 'utils'
import { MicrositeDesktopNav } from './MicrositeDesktopNav'
import { MicrositeLinks, PrismicImage } from 'types/util'
import Image from 'next/image'
import { Layout, LayoutNav } from 'types/graphql'
import cn from 'classnames'
import { FlexibleLink } from 'components/Shared/FlexibleLink'
import type { PropsWithChildren } from 'react'

type Props = { lang?: string } & (
  | { links: Layout['nav']; microsite?: undefined; micrositeLogo?: undefined }
  | { links: MicrositeLinks; microsite: string; micrositeLogo: PrismicImage }
)

export const Logo = () => {
  const [backgroundPosition, setBackgroundPosition] = useState(0)

  const allLogosHeight = 1792
  const logoHeight = 42

  useEffect(() => {
    const interval = setInterval(() => {
      setBackgroundPosition(backgroundPosition =>
        backgroundPosition < allLogosHeight ? backgroundPosition + logoHeight : 0
      )
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div
      style={{
        backgroundImage: `url('/logo-symboly.svg')`,
        width: '120px',
        height: '38px',
        backgroundSize: 'cover',
        backgroundPositionY: `${backgroundPosition}px`,
      }}
    />
  )
}

export const Header: FC<PropsWithChildren<Props>> = ({ lang, links, microsite, micrositeLogo }) => {
  const [navState, setNavState] = useState({
    isMenuActive: false,
    hasScrolled: false,
    scrollingUp: false,
    isHovered: false,
  })

  useEffect(() => {
    let scrollPosition = 0

    function handleScroll() {
      setNavState(state => ({
        ...state,
        hasScrolled: 18 < window.scrollY,
        scrollingUp: scrollPosition > window.pageYOffset,
      }))

      scrollPosition = window.pageYOffset
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const handleHover = useCallback((isHover: boolean) => {
    setNavState(state => ({
      ...state,
      isHovered: isHover,
    }))
  }, [])

  const desktopNavClasses = cn(
    navState.hasScrolled && !navState.scrollingUp
      ? 'lg:opacity-0 lg:invisible'
      : 'lg:opacity-1 lg:visible',
    'transition-all duration-500'
  )

  return (
    <header className="fixed top-0 z-20 flex h-90 w-full bg-white px-20 lowercase transition-colors duration-500 ease-in-out lg:bg-transparent lg:px-25">
      <div
        className={cn('mx-auto flex w-full items-center text-black100', {
          'justify-between': !microsite,
        })}
      >
        <FlexibleLink
          data={ROUTES.index}
          nextLinkProps={{ prefetch: false }}
          className={cn(
            !microsite && {
              'w-16': navState.hasScrolled,
              'w-120': !navState.hasScrolled,
            },
            microsite && 'origin-left scale-90 transform xxs:scale-100',
            'flex-shrink-0 overflow-hidden transition-width duration-500 ease-in-out'
          )}
        >
          <Logo />
        </FlexibleLink>
        {microsite ? (
          <>
            <div className="mb-12 ml-1 mr-10 flex-shrink-0 text-18 xxs:ml-14 xxs:mr-12 xxs:text-20">
              na téma
            </div>
            <FlexibleLink
              data={ROUTES.microsite_landing_page(microsite)}
              className="mb-7 flex-shrink"
              nextLinkProps={{ prefetch: false }}
            >
              {micrositeLogo?.url && <Image src={micrositeLogo?.url} width={92} height={18} />}
            </FlexibleLink>
            <MicrositeDesktopNav
              lang={lang}
              links={links as MicrositeLinks}
              className={desktopNavClasses}
            />
          </>
        ) : (
          <>
            <DesktopNav
              handleHover={handleHover}
              lang={lang}
              links={links as LayoutNav[]}
              className={cn(
                navState.isHovered
                  ? 'lg:opacity-1 lg:visible'
                  : navState.hasScrolled && !navState.scrollingUp
                  ? 'lg:invisible lg:opacity-0'
                  : 'lg:opacity-1 lg:visible',
                'transition-all duration-500'
              )}
            />
            <MobileNav
              lang={lang}
              links={links as Layout['nav']}
              active={navState.isMenuActive}
              toggleMenu={() =>
                setNavState(state => ({ ...state, isMenuActive: !state.isMenuActive }))
              }
            />
          </>
        )}
      </div>
    </header>
  )
}
