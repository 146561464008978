export const isWindows = () => {
  if (typeof window === 'undefined') return false

  const platform = window.navigator.platform
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']

  if (windowsPlatforms.includes(platform)) return true
  return false
}

export const formatDate = (
  date: string,
  options?: { separator?: string; withStartingZeros?: boolean }
) => {
  const isoDate = new Date(date)
  const orderedArray = [isoDate.getDate(), isoDate.getMonth() + 1, isoDate.getFullYear()]

  const withStartingZeros = orderedArray.map(datePart =>
    datePart < 10 ? '0' + datePart : datePart
  )

  const finalArray = options?.withStartingZeros ? withStartingZeros : orderedArray

  return finalArray.join(options?.separator || '.')
}

export const formatTime = (
  date: string,
  options?: { separator?: string; showSeconds?: boolean }
) => {
  const isoDate = new Date(date)
  const orderedArray = [isoDate.getHours(), isoDate.getMinutes()]
  options?.showSeconds && orderedArray.push(isoDate.getSeconds())
  const withStartingZeros = orderedArray.map(datePart =>
    datePart < 10 ? '0' + datePart : datePart
  )
  return withStartingZeros.join(options?.separator || ':')
}

export const getHeightInPercentageOfWidth = (
  width?: number,
  height?: number,
  heightMultiplier?: number
) => {
  if (!width || !height) return undefined
  return `${(Math.round((height / width) * 100 * 10) / 10) * (heightMultiplier || 1)}%`
}

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
