import { Meta } from './Meta'
import { FC } from 'react'
import type { PropsWithChildren } from 'react'

interface LayoutBaseProps {
  preview?: boolean
  title: string
  customTitleBase?: string
  description: string
  metaImage?: string
  microsite?: string
}

export const LayoutBase: FC<PropsWithChildren<LayoutBaseProps>> = ({
  preview,
  title,
  customTitleBase,
  description,
  metaImage,
  children,
  microsite,
}) => {
  return (
    <div className="flex h-screen flex-col" id="layoutInner">
      {preview && (
        <div className="fixed bottom-0 z-30 w-full bg-yellow p-5 text-center">
          [Preview mode]{' '}
          <a href="/api/exit-preview" className="text-blue-800 font-bold underline">
            Click here
          </a>{' '}
          to exit preview mode.
        </div>
      )}
      <Meta
        title={(customTitleBase ?? 'Applifting | ') + title}
        description={description}
        meta_image={metaImage}
        microsite={microsite}
      />
      {children}
    </div>
  )
}
