import { FlexibleLink } from 'components/Shared/FlexibleLink'
import { MicrositeLinks } from 'types/util'
import { FC } from 'react'
import type { PropsWithChildren } from 'react'

interface Props {
  lang?: string
  className?: string
  links: MicrositeLinks
}

export const MicrositeDesktopNav: FC<PropsWithChildren<Props>> = ({ links, className }) => {
  return (
    <nav className={`ml-auto hidden h-full items-center text-14 lg:flex ` + className}>
      <div className="group hidden lg:ml-30 lg:flex">
        {links?.map(({ link }, i) => (
          <FlexibleLink
            data={link}
            key={i}
            className="nav-item group flex items-center px-20 text-right normal-case duration-500 first:pl-0 last:pr-0 group-hover:opacity-60"
            nextLinkProps={{ prefetch: false }}
          >
            {link.title}
          </FlexibleLink>
        ))}
      </div>
    </nav>
  )
}
